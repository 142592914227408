import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  ScrollView,
  TouchableOpacity,
  Linking,
  Alert,
} from "react-native";
import Accordion from "react-native-collapsible/Accordion";
import { trackEvent } from "../../../utils/tracking";
import { Header } from "../Header";
import * as Clipboard from "expo-clipboard";

type PropsType = {
  navigation: any;
};

const FAQList = [
  {
    question: "What is Athelas?",
    answer:
      "Athelas is a biotechnology company that has partnered with your prescriber to provide you with remote health devices. Our program allows your prescriber to take an active role in your care to help ensure that you do not experience any unforeseen health issues.",
  },
  {
    question: "Why was I identified for this program?",
    answer:
      "Based on your care from your provider, they have identified you as being at higher than normal risk and therefore a candidate for the Athelas program.",
  },
  {
    question: "Who pays for this?",
    answer:
      "Athelas has already pre-screened your insurance information to ensure that your insurance covers this program.",
  },
  {
    question: "I'm having trouble with my device. Who can help?",
    answer:
      "If you are having any issues with your device, or have any questions at all, you can call or text Athelas support team at 877-324-4332, Athelas nurse team at 408-833-6682 or email us at help@getathelas.com. We will get back to you within 24 hours.",
  },
  {
    question: "Is my information secure?",
    answer:
      "Always - your privacy and security are our #1 priority. Everything we do is protected by HIPAA.",
  },
];

export function FAQ(props: PropsType) {
  const [activeSections, setActiveSections] = useState([]);

  const _renderHeader = (section) => {
    let title = section.question;
    return (
      <View style={styles.header}>
        <Text style={styles.headerText}>{title}</Text>
      </View>
    );
  };

  const _updateSections = (activeSections) => {
    setActiveSections(activeSections);
  };

  return (
    <View style={styles.container}>
      <Header title="FAQ"></Header>

      <ScrollView>
        <View style={styles.banner}>
          <Text style={{ textAlign: "center", marginBottom: 4 }}>
            Have questions? Contact us:
          </Text>
          <TouchableOpacity
            onPress={() => {
              trackEvent("click", "tapped_support_email");
              Clipboard.setString("help@getathelas.com");
              Alert.alert(
                "Copied",
                "Copied 'help@getathelas.com' to clipboard"
              );
              // Linking.openURL("mailto:help@getathelas.com");
            }}
          >
            <Text style={styles.bannerText}>help@getathelas.com</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              trackEvent("click", "tapped_support_call");
              Linking.openURL("tel:8773244332");
            }}
          >
            <Text style={styles.bannerText}>(877) 324-4332</Text>
          </TouchableOpacity>
        </View>

        <Accordion
          sections={FAQList}
          activeSections={activeSections}
          renderHeader={_renderHeader}
          renderContent={(section) => (
            <View>
              <Text style={styles.answer}>{section.answer}</Text>
            </View>
          )}
          onChange={_updateSections}
        />

        <TouchableOpacity
          style={styles.banner}
          onPress={() => {
            trackEvent("click", "opening_patient_consent");
            props.navigation.navigate("Patient Consent");
          }}
        >
          <Text style={{ textAlign: "center", marginBottom: 4 }}>
            Athelas Documentation
          </Text>
          <Text style={styles.bannerText}>Patient Consent Document</Text>
        </TouchableOpacity>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
  },
  banner: {
    backgroundColor: "#efefef",
    margin: 24,
    padding: 24,
  },
  bannerText: {
    fontSize: 18,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 8,
  },
  header: {
    padding: 16,
    paddingLeft: 24,
    paddingRight: 24,
    display: "flex",
    flexDirection: "row",
    borderTopColor: "#FF6182",
    borderTopWidth: 1,
  },
  headerText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#FF6182",
    width: "90%",
  },
  answer: {
    margin: 24,
    marginTop: 0,
    fontSize: 16,
    color: "#888",
  },
});
