import {
  View,
  Button,
  Text,
  Image,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  Alert,
  Platform,
  ScrollView,
  Dimensions,
  KeyboardAvoidingView,
  Linking,
} from "react-native";
import React, { useState } from "react";
import AthelasLogo from "../../assets/logo.png";
import DoctorFlatDesign from "./assets/doctors-flat-design.svg";
import DoctorFlatDesignPNG from "./assets/doctors-flat-design.png";
import { attemptLogin } from "./actions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import AppBanner from "../../components/AppBanner";

type PropsType = {
  navigation: any;
};

export function LoginAttempt(props: PropsType) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [displayBanner, toggleBanner] = useState(true);

  AsyncStorage.getItem("token").then((token) => {
    if (token) {
      props.navigation.navigate("Main");
    }
  });

  const onLoginClicked = () => {
    const phone = phoneNumber
      .replace(/\(/g, "")
      .replace(/\)/g, "")
      .replace(/ - /g, "");

    setErrorMessage("");
    attemptLogin(phone)
      .then((res) => {
        if (res.status === 200) {
          props.navigation.navigate("Confirm Login", {
            phoneNumber: phoneNumber,
          });
        } else {
          setErrorMessage(
            "Patient not found or phone number not connected. Contact help@getathelas.com for assistance."
          );
        }
      })
      .catch((err) => {
        setErrorMessage(err);
      });
  };

  const LoginView = (
    <ScrollView>
      <View
        style={{
          flex: 1,
          alignItems: "center",
          backgroundColor: "white",
          height: Dimensions.get("window").height * 1.25,
          width: Dimensions.get("window").width,
        }}
      >
        <Image
          source={AthelasLogo}
          style={styles.athelasLogo}
          resizeMode="contain"
        />
        <Image
          source={DoctorFlatDesignPNG}
          style={styles.highlightImage}
          resizeMode="contain"
        />

        <View style={styles.jumbotron}>
          <Text style={styles.header}>Athelas Patient Portal</Text>
          <Text style={styles.subtitle}>
            All your remote monitoring data, medical records, doctor
            transcripts, and more in one place.
          </Text>
        </View>

        <View>
          <Text style={styles.enterPhoneNumberText}>
            Enter your phone number to sign in:
          </Text>

          <TextInput
            keyboardType="numeric"
            style={styles.phoneNumberInput}
            onChangeText={(text) => {
              if (
                text.includes("(") &&
                !text.includes(")") &&
                text.length == 4 &&
                phoneNumber.length == 5
              ) {
                setPhoneNumber(phoneNumber.substring(1, 3));
                return phoneNumber.substring(1, 3);
              }
              text = text
                .replace(/\(/g, "")
                .replace(/\)/g, "")
                .replace(/ - /g, "");

              var formatted = text.replace(
                /(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/,
                function (_, p1, p2, p3, p4) {
                  let output = "";
                  if (p1) output = `(${p1}`;
                  if (p2) output += `${p2})`;
                  if (p3) output += ` - ${p3}`;
                  if (p4) output += ` - ${p4}`;
                  return output;
                }
              );
              setPhoneNumber(formatted);
            }}
            value={phoneNumber}
            placeholder="ex. (441) 321 2921"
            placeholderTextColor="#CFCFCF"
          />
        </View>

        <Text style={styles.errorMessage}>{errorMessage + ""}</Text>

        <TouchableOpacity
          onPress={onLoginClicked}
          style={{ marginBottom: 100 }}
        >
          <View style={styles.loginButtonContainer}>
            <Text style={styles.loginButtonText}>LOG IN</Text>
          </View>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );

  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <AppBanner />
      {Platform.OS === "android" ? (
        LoginView
      ) : (
        <KeyboardAvoidingView behavior="padding">
          {LoginView}
        </KeyboardAvoidingView>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  loginButtonContainer: {
    backgroundColor: "#FE5E85",
    padding: 12,
    paddingLeft: 48,
    paddingRight: 48,
    borderRadius: 16,
    width: "100%",
  },
  loginButtonText: { color: "white", fontWeight: "bold" },
  phoneNumberInput: {
    width: "100%",
    maxWidth: 300,
    marginTop: 8,
    borderWidth: 3,
    borderColor: "#E5E5E5",
    borderStyle: "solid",
    backgroundColor: "#FDFDFD",
    textAlign: "center",
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 16,
    marginBottom: 16,
    fontSize: 18,
  },
  enterPhoneNumberText: {
    fontSize: 18,
    color: "#565F76",
    marginTop: 16,
    textAlign: "center",
  },
  subtitle: { fontSize: 16, color: "#565F76", textAlign: "center" },
  header: {
    fontWeight: "bold",
    fontSize: 20,
    marginBottom: 8,
    color: "#565F76",
    textAlign: "center",
  },
  highlightImage: {
    width: 240,
    height: 180,
    marginTop: -20,
    marginLeft: "auto",
    marginRight: "auto",
  },
  jumbotron: {
    padding: 16,
    borderRadius: 16,
    backgroundColor: "#EAEAEA",
    marginTop: 24,
    maxWidth: 300,
  },
  athelasLogo: {
    width: 160,
    height: 100,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 64,
  },
  errorMessage: {
    fontSize: 14,
    color: "red",
    maxWidth: 300,
    marginBottom: 8,
    textAlign: "center",
  },
});
